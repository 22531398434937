import type { NextPage, GetStaticProps } from 'next';
import { NextSeo } from 'next-seo';
import Link from 'next/link';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { getMenus } from '@/layout/category-menu/menuItems';
import type { DocInterface } from '@/utilities/doc';
import { getDoc, serializeDoc } from '@/utilities/doc';

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const doc = getDoc('home', locale);
  const { content, frontmatter } = doc;

  const mdxSource = await serializeDoc(content);

  return await {
    props: {
      source: mdxSource,
      meta: frontmatter,
    },
  };
};

const Home: NextPage<DocInterface> = () => {
  const description =
    'Thandhu is an online free marketplace for web apps where you can find useful calculators and utilities.';
  return (
    <>
      <NextSeo
        title="Online Free Marketplace for Useful Web Apps"
        description={description}
      />
      {/* <div className="container mx-auto pt-8 prose dark:prose-invert">
        <MDXRemote {...source} />
      </div> */}
      <div className="container mx-auto pt-8 prose dark:prose-invert">
        <h2 className="text-3xl">
          <FormattedMessage id="home.nav.allApps" />
        </h2>
        {getMenus().map((menu) => (
          <div key={menu.id}>
            <h2 className="text-xl">
              <FormattedMessage id={`home.nav.${menu.name}`} />
            </h2>
            <ul>
              {menu.apps.map((app) => (
                <li key={app.slug}>
                  <Link href={`/app/${app.slug}`}>{app.name}</Link>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </>
  );
};

export default Home;
